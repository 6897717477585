.history-table {
  text-align: center;
  box-shadow: 0px 0px 10px #30303029;
  border-radius: 20px;
  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  width: 100%;
}

.history-table th:not(:last-of-type) {
  border-right: 1px solid #dddddd;
}

.history-table td {
  border: 1px solid #dddddd;
}

.history-table tr > td:first-of-type {
  border-left: none;
}

.history-table tr > td:last-of-type {
  border-right: none;
}

.history-table tr:last-of-type > td {
  border-bottom: none;
}

.history-table th {
  background-color: var(--darkblue);
  color: #fff;
  font-size: 15px;
  padding: 20px;
}

.history-table th:first-of-type {
  border-radius: 20px 0 0 0;
}

.history-table th:last-of-type {
  border-radius: 0 20px 0 0;
}

.history-table td {
  padding: 20px;
  color: var(--text-secondary);
}

@media screen and (min-width: 769px) and (max-width: 1400px) {
  .history-table {
    max-width: 80%;
    margin: 0 auto;
  }
}
