@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --body-bg: #f0f3f4;
  --text-primary: #2c3c46;
  --text-secondary: #777777;
  --text-accent: #d2d369;
  --darkblue: #19647e;
}

@font-face {
  font-family: 'Commissioner';
  src: local('Commissioner'),
    url(./assets/fonts/Commissioner-VariableFont_wght.ttf) format('truetype');
}

html,
body {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: 'Commissioner', sans-serif;
  color: var(--text-primary);
}

input,
select {
  outline: 0;
}

body {
  background-color: var(--body-bg);
}

#root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

/* This will remove te blue color when autocompleting the fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type='radio'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  -moz-appearance: none;
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type='radio']::before {
  content: '';
  width: 0.72em;
  height: 0.72em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

/* Specifically hide the dialog element because React renders it when loading the DOM */
dialog:not([open]) {
  display: none !important;
}
